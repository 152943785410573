@font-face {
  font-family: Poppins-Light;
  src: url(../../../assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(../../../assets/fonts/Poppins-SemiBold.ttf);
}
.field-label {
  font-family: Poppins-Regular;
}
.input-wrapper input {
  border: none;
  width: 100%;
}
.input-wrapper input:focus {
  /* border: none; */
  outline: none;
}
.input-wrapper {
  border: solid 1px #ccc;
  border-radius: 5px;
}
.input-wrapper:focus-within {
  border: solid 1px #7367f0;
}
.dropdown-field {
  font-family: Poppins-Regular;
}
.dropdown-field:hover {
  border: none !important;
}
.error-border {
  border: solid 1px red !important;
  border-radius: 5px;
}
.error-text {
  font-size: 13px;
  color: red;
}
.error-text-wrapper {
  position: absolute;
  bottom: -20px;
}
