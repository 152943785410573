@font-face {
  font-family: Poppins-Light;
  src: url(../../../assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(../../../assets/fonts/Poppins-SemiBold.ttf);
}
.back-button {
  background: red;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  font-family: Poppins-Regular;
}
.back-button-disabled {
  background: rgb(255, 159, 159);
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  font-family: Poppins-Regular;
}
