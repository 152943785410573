@font-face {
  font-family: Poppins-Light;
  src: url(../../../assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(../../../assets/fonts/Poppins-SemiBold.ttf);
}
.submit-button {
  background: #08467f;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  font-family: Poppins-Regular;
}
.auth-submit-button {
  background: #6200ff;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-family: Poppins-SemiBold;
}
.auth-submit-button-disabled {
  background: #b07fff !important;
}
